export const SUBMISSION_STATUS_WAIT_FOR_PAYMENT = 'WAIT_FOR_PAYMENT';
export const SUBMISSION_STATUS_COMPELETED = 'COMPLETED';

export const PAYMENT_STATUS_COMPELETED = 'COMPLETED';
export const PAYMENT_STATUS_PAYING = 'PAYING'; // used for user paying from Snappay
export const PAYMENT_PROCESS_SUCCEEDED = 'SUCCESS'; // used for success payment url redirect 

export const CHARGE_PER_ARTWORK = 20.0;
export const PAYMENT_CURRENCY = 'USD';

/**
 * Extract the reference number to id and info_unixtimestamp
 * @param {string} referenceNumber 
 * @returns { id, info_unixtimestamp }
 */
export const extractReferenceNumber = (referenceNumber) => {
  const lastIndex = referenceNumber.lastIndexOf('#');
  return {
    id: referenceNumber.slice(0, lastIndex),
    info_unixtimestamp: referenceNumber.slice(lastIndex + 1, referenceNumber.length)
  };
}

/**
 * Get all params from a url
 * @returns all params in a map
 */
export const getUrlParams = () => {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}