/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission(
    $input: CreateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    createSubmission(input: $input, condition: $condition) {
      id
      info_unixtimestamp
      owner
      timestamp
      status
      contest_name
      reference_number
      participant
      date_of_birth
      email
      country
      state
      city
      school
      instructor
      artwork_name
      artwork_material
      artwork_summary
      artwork_file_size
      artwork_file_location
      num_of_artworks
      payment_method
      payment_amount
      payment_timestamp
      payment_currency
      payment_status
      payment_trans_no_snappay
      payment_out_order_no_snappay
      payment_order_id_moneris
      payment_reference_moneris
      createdAt
      updatedAt
    }
  }
`;
export const updateSubmission = /* GraphQL */ `
  mutation UpdateSubmission(
    $input: UpdateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    updateSubmission(input: $input, condition: $condition) {
      id
      info_unixtimestamp
      owner
      timestamp
      status
      contest_name
      reference_number
      participant
      date_of_birth
      email
      country
      state
      city
      school
      instructor
      artwork_name
      artwork_material
      artwork_summary
      artwork_file_size
      artwork_file_location
      num_of_artworks
      payment_method
      payment_amount
      payment_timestamp
      payment_currency
      payment_status
      payment_trans_no_snappay
      payment_out_order_no_snappay
      payment_order_id_moneris
      payment_reference_moneris
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubmission = /* GraphQL */ `
  mutation DeleteSubmission(
    $input: DeleteSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    deleteSubmission(input: $input, condition: $condition) {
      id
      info_unixtimestamp
      owner
      timestamp
      status
      contest_name
      reference_number
      participant
      date_of_birth
      email
      country
      state
      city
      school
      instructor
      artwork_name
      artwork_material
      artwork_summary
      artwork_file_size
      artwork_file_location
      num_of_artworks
      payment_method
      payment_amount
      payment_timestamp
      payment_currency
      payment_status
      payment_trans_no_snappay
      payment_out_order_no_snappay
      payment_order_id_moneris
      payment_reference_moneris
      createdAt
      updatedAt
    }
  }
`;
