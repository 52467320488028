import React, { Component } from 'react';
import logo from './../images/AAC_white_trans.png';
import 'element-theme-default';
import Amplify from 'aws-amplify';
import PubSub from '@aws-amplify/pubsub';
import { I18n } from 'aws-amplify';
import { dic } from '../i18n/vocabularies';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import SubmissionEdit from './submission/SubmissionEdit';
import PaymentEdit from './payment/PaymentEdit';
import aws_exports from '../aws-exports';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CompleteView from './complete/CompleteView';
import TrackingView from './tracking/TrackingView';

Amplify.configure(aws_exports);
PubSub.configure(aws_exports);
I18n.putVocabularies(dic);
I18n.setLanguage("en");

class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">
          <header className="App-header sticky">
            <div>
              <img src={logo} className="App-logo" alt="logo" />
              <span className="app-header-text">2023 Canada International Art Competition</span>
            </div>
          </header>
          <Switch>
            <Route path="/submission" component={SubmissionEdit} />
            <Route path="/payment" component={PaymentEdit} />
            <Route path="/complete" component={CompleteView} />
            <Route path="/tracking" component={TrackingView} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
