/**
 * Check if user on mobile
 * @returns true/false
 */
export const isUserOnMobile = () => {
  return !!navigator.userAgent.match(
    /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
}

/**
 * Get payment success redirect url
 * @param {string} referenceNumber 
 */
export const getPaymentSuccessRedirectUrl = (referenceNumber) => {
  return "/payment?referenceNumber=" + referenceNumber + "&paymentStatus=SUCCESS";
}

export const AXIOS_REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
  }
}

export const PAYMENT_TYPE_SNAPPAY_WECHATPAY = 'SNAPPAY_WECHATPAY'
export const PAYMENT_TYPE_CREDIT_CARD_MONERIS_PAYMENT = 'CREDIT_CARD_MONERIS_PAYMENT'
export const PAYMENT_TYPE_PAYMENT_WAIVED = 'PAYMENT_WAIVED'