import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react'
import { I18n, Auth, Storage } from 'aws-amplify';
import { FormControl, FormControlLabel, TextField, FormHelperText, Grid, Tabs, Tab, Checkbox } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone'
import API, { graphqlOperation } from '@aws-amplify/api';
import { createSubmission } from '../../graphql/mutations';
import { Alert, Modal, Button, Spinner } from 'react-bootstrap';
import { SUBMISSION_STATUS_WAIT_FOR_PAYMENT } from '../common';
import './SubmissionEdit.css';

const FILE_SIZE_LIMIT_FOR_INDIVIDUAL = 5000000; // 5 MB
const FILE_SIZE_LIMIT_FOR_ORGANIZATION = 500000000; // 500 MB
const S3_BUCKET_NAME_PREFIX_FOR_ARTWORK_FILES = "artwork-files/";
const S3_BUCKET_NAME_PATH_DASH = "-";

class SubmissionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contestType: "A", // A: Individual B: Organization
      email: "",
      participant: "",
      dateOfBirth: "",
      country: "",
      state: "",
      city: "",
      school: "",
      instructor: "",
      artworkName: "",
      artworkSummary: "",
      artworkMaterial: "",
      artworkFile: null,
      numOfArtworks: 1,
      isSubmitSucceded: false,
      refreshDropFileAreaKey: 0,
      errorMessage: "",
      referenceNumber: "",
      contestName: "2023 Canada International Art Competition",
      isAcknowledged: false,
      isSubmitButtonDisabled: false,
      showModal: false,
      showSpinner: false,
    }
    Auth.currentAuthenticatedUser().then((re) => {
      this.setState({
        email: re.attributes.email
      });
    });
  }

  onContestTypeChangeToIndividual = () => {
    this.setState({ contestType: "A", isAcknowledged: false, numOfArtworks: 1 });
    if (this.state.artworkFile && this.state.artworkFile.size > FILE_SIZE_LIMIT_FOR_INDIVIDUAL) {
      // force refresh the drop file area to clear all the files 
      this.setState({
        artworkFile: null,
        refreshDropFileAreaKey: this.state.refreshDropFileAreaKey + 1
      });
    }
  }
  onContestTypeChangeToOrganization = () => { this.setState({ contestType: "B", isAcknowledged: false }) }
  onParticipantChange = (event) => { this.setState({ participant: event.target.value }) }
  onDateOfBirthChange = (event) => { this.setState({ dateOfBirth: event.target.value }) }
  onCountryChange = (event) => { this.setState({ country: event.target.value }) }
  onStateChange = (event) => { this.setState({ state: event.target.value }) }
  onCityChange = (event) => { this.setState({ city: event.target.value }) }
  onSchoolChange = (event) => { this.setState({ school: event.target.value }) }
  onInstructorChange = (event) => { this.setState({ instructor: event.target.value }) }
  onArtworkNameChange = (event) => { this.setState({ artworkName: event.target.value }) }
  onArtworkSummaryChange = (event) => { this.setState({ artworkSummary: event.target.value }) }
  onArtworkMaterialChange = (event) => { this.setState({ artworkMaterial: event.target.value }) }
  onNumOfArtworksChange = (event) => { this.setState({ numOfArtworks: event.target.value }) }
  onAcknowledgementChange = (event) => { this.setState({ isAcknowledged: event.target.value === "true" ? true : false }) };
  onUploadArtworkFile = (files) => {
    if (files.length === 1) this.setState({ artworkFile: files[0] })
    if (files.length === 0) this.setState({ artworkFile: null })
  }
  onCloseModal = () => { this.setState({ showModal: false }) }
  onSubmitForm = (event) => {
    event.preventDefault(); // prevent the page refresh
    this.setState({ errorMessage: "" });
    try {
      this.validateForm();
      this.setState({ showModal: true });
    } catch (er) {
      this.showErrorAlert(er);
    }
  }
  onModalConfirm = async () => {
    this.setState({ showSpinner: true });
    try {
      await this.submitSubmission();
    } catch (er) {
      this.showErrorAlert(er);
    } finally {
      this.setState({ showSpinner: false });
      this.onCloseModal();
    }
  }

  validateForm = () => {
    if (!this.state.artworkFile) {
      throw new Error(I18n.get('artworks_number_limit_error_message'));
    }
    if (!this.state.isAcknowledged) {
      throw new Error(I18n.get('acknowledgement_error_message'));
    }
  }

  submitSubmission = async () => {
    this.setState({ isSubmitButtonDisabled: true }); // lock the button to prevent user click multiple times

    const submission = {};
    const now = new Date();
    submission.id = this.state.contestType + "#2023CIAC#" + this.state.email;
    submission.info_unixtimestamp = Math.floor(now.getTime() / 1000).toString();
    submission.reference_number = `${submission.id}#${submission.info_unixtimestamp}`;
    submission.contest_name = this.state.contestName;
    submission.timestamp = now.toISOString();
    submission.email = this.state.email;
    submission.country = this.state.country;
    submission.state = this.state.state;
    submission.city = this.state.city;
    submission.school = this.state.school;
    submission.num_of_artworks = this.state.numOfArtworks;
    submission.status = SUBMISSION_STATUS_WAIT_FOR_PAYMENT;
    if (this.state.contestType === "A") {
      submission.participant = this.state.participant;
      submission.date_of_birth = this.state.dateOfBirth;
      submission.artwork_name = this.state.artworkName;
      submission.artwork_summary = this.state.artworkSummary;
      submission.artwork_material = this.state.artworkMaterial;
      if (!!this.state.instructor) submission.instructor = this.state.instructor;
    }

    await this.persistSubmission(submission);
  }

  persistSubmission = async (submission) => {
    const result = await Storage.put(S3_BUCKET_NAME_PREFIX_FOR_ARTWORK_FILES + submission.reference_number + S3_BUCKET_NAME_PATH_DASH + this.state.artworkFile.name, this.state.artworkFile);
    submission.artwork_file_location = result.key;
    await API.graphql(graphqlOperation(createSubmission, { input: submission }));
    window.scrollTo(0, 0);
    this.setState({ isSubmitSucceded: true, referenceNumber: submission.reference_number });
    window.location.href = '/payment?referenceNumber=' + submission.reference_number;
  }

  showErrorAlert = (er) => {
    this.setState({
      isSubmitButtonDisabled: false,
      isSubmitSucceded: false,
      errorMessage: er.errors ? er.errors.map(e => e.message).join() : er
    });
    window.scrollTo(0, 0);
  }

  render() {
    const { contestType, email, participant, dateOfBirth, country, state, city, instructor, school,
      artworkName, artworkSummary, artworkMaterial, numOfArtworks, refreshDropFileAreaKey,
      errorMessage, isAcknowledged, isSubmitButtonDisabled, showModal, showSpinner } = this.state;

    return (
      <div className='contest-info-form'>

        {!!errorMessage && <Alert variant="danger">
          <p>{`${I18n.get('submit_error_message')} ${errorMessage}`}</p>
        </Alert>}

        <Modal className="modal" show={showModal} onHide={this.onCloseModal} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>{I18n.get('submit_modal_header')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            {showSpinner && <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>}
            {!showSpinner && I18n.get('submit_modal_body')}
          </Modal.Body>
          {!showSpinner && <Modal.Footer>
            <Button variant="secondary" onClick={this.onCloseModal}>{I18n.get('submit_modal_close')}</Button>
            <Button variant="primary" onClick={this.onModalConfirm}>{I18n.get('submit_modal_confirm')}</Button>
          </Modal.Footer>}
        </Modal>

        <form onSubmit={this.onSubmitForm}>
          <Grid className='grid-input'>
            <Tabs
              className='tabs'
              value={contestType}
              indicatorColor="primary"
              textColor="primary">
              <Tab label={I18n.get('individual')} value="A" onClick={this.onContestTypeChangeToIndividual} />
              <Tab label={I18n.get('organization')} value="B" onClick={this.onContestTypeChangeToOrganization} />
            </Tabs>
          </Grid>
          <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-email"
                label={I18n.get('email_address')}
                variant="outlined"
                size="small"
                value={email}
                type="email"
                disabled
                required />
              <FormHelperText>{I18n.get('email_address_helper_text')}</FormHelperText>
            </FormControl>
          </Grid>
          {contestType === "A" && <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-participant"
                label={I18n.get('participant')}
                variant="outlined"
                size="small"
                value={participant}
                type="text"
                onChange={this.onParticipantChange}
                required />
              <FormHelperText>{I18n.get('participant_helper_text')}</FormHelperText>
            </FormControl>
          </Grid>}
          {contestType === "A" && <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-date-of-birth"
                label={I18n.get('date_of_birth')}
                size="small"
                type="date"
                value={dateOfBirth}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.onDateOfBirthChange}
                required />
            </FormControl>
          </Grid>}
          <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-country"
                label={I18n.get('country')}
                variant="outlined"
                size="small"
                value={country}
                type="text"
                onChange={this.onCountryChange}
                required />
            </FormControl>
          </Grid>
          <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-state"
                label={I18n.get('state')}
                variant="outlined"
                size="small"
                value={state}
                type="text"
                onChange={this.onStateChange}
                required />
            </FormControl>
          </Grid>
          <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-city"
                label={I18n.get('city')}
                variant="outlined"
                size="small"
                value={city}
                type="text"
                onChange={this.onCityChange}
                required />
            </FormControl>
          </Grid>
          {contestType === "A" && <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-instructor"
                label={I18n.get('instructor')}
                variant="outlined"
                size="small"
                value={instructor}
                type="text"
                onChange={this.onInstructorChange} />
              <FormHelperText>{I18n.get('instructor_helper_text')}</FormHelperText>
            </FormControl>
          </Grid>}
          <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-school"
                label={I18n.get('school')}
                variant="outlined"
                size="small"
                value={school}
                type="text"
                onChange={this.onSchoolChange}
                required />
            </FormControl>
          </Grid>
          {contestType === "A" && <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-artwork-name"
                label={I18n.get('artwork_name')}
                variant="outlined"
                size="small"
                value={artworkName}
                type="text"
                onChange={this.onArtworkNameChange}
                required />
              <FormHelperText>{I18n.get('artwork_name_helper_text')}</FormHelperText>
            </FormControl>
          </Grid>}
          {contestType === "A" && <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-artwork-summary"
                label={I18n.get('artwork_summary')}
                variant="outlined"
                size="small"
                value={artworkSummary}
                type="text"
                onChange={this.onArtworkSummaryChange}
                required />
              <FormHelperText>{I18n.get('artwork_summary_helper_text')}</FormHelperText>
            </FormControl>
          </Grid>}
          {contestType === "A" && <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-artwork-material"
                label={I18n.get('artwork_material')}
                variant="outlined"
                size="small"
                value={artworkMaterial}
                type="text"
                onChange={this.onArtworkMaterialChange}
                required />
              <FormHelperText>{I18n.get('artwork_material_helper_text')}</FormHelperText>
            </FormControl>
          </Grid>}
          {contestType === "B" && <Grid className='grid-input'>
            <FormControl className='form-input'>
              <TextField
                id="form-num-of-artworks"
                label={I18n.get('num_of_artworks')}
                variant="outlined"
                size="small"
                value={numOfArtworks}
                type="number"
                onChange={this.onNumOfArtworksChange}
                required />
            </FormControl>
          </Grid>}
          <Grid className='grid-input'>
            <FormControl className='form-input'>
              <DropzoneArea
                key={refreshDropFileAreaKey}
                onChange={this.onUploadArtworkFile}
                acceptedFiles={contestType === 'A' ? ['image/png', 'image/gif', 'image/jpeg'] : ['application/zip']}
                filesLimit={1}
                required
                maxFileSize={contestType === 'A' ? FILE_SIZE_LIMIT_FOR_INDIVIDUAL : FILE_SIZE_LIMIT_FOR_ORGANIZATION} />
              <FormHelperText>{contestType === 'A' ? I18n.get('artworks_drop_file_area_helper_text_individual') : I18n.get('artworks_drop_file_area_helper_text_organization')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid className='grid-input acknowledgement'>
            <FormControl className='form-input'>
              <div>
                <span>{I18n.get('more_info_part1')}</span>
                <a href="http://www.artistscanada.org/contest"
                  rel="noopener noreferrer"
                  target="_blank">
                  http://www.artistscanada.org/contest
                </a>
                <span>{I18n.get('more_info_part2')}</span>
              </div>
              <br />
              <FormControlLabel
                className='acknowledgement'
                control={
                  <Checkbox
                    checked={isAcknowledged}
                    value={!isAcknowledged}
                    onChange={this.onAcknowledgementChange} />
                }
                label={<span className='acknowledgement'>{contestType === "A" ? I18n.get('acknowledgement_individual') : I18n.get('acknowledgement_organization')}</span>} />
              <a href="http://artistscanada.org/wp-content/uploads/2023/08/Terms-and-Conditions-20231.pdf"
                rel="noopener noreferrer"
                target="_blank">
                http://artistscanada.org/wp-content/uploads/2023/08/Terms-and-Conditions-20231.pdf
              </a>
            </FormControl>
          </Grid>
          {!isSubmitButtonDisabled && <div className="submit-button">
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitButtonDisabled}>
              {I18n.get('submit')}
            </Button>
          </div>}
        </form>
      </div>
    )
  }
}

export default withAuthenticator(SubmissionEdit);