import React, { Component } from 'react';
// import { withAuthenticator } from 'aws-amplify-react';
// import { Alert } from 'react-bootstrap';
import { getUrlParams, extractReferenceNumber } from '../common';
// import { I18n } from 'aws-amplify';
import API, { graphqlOperation } from '@aws-amplify/api';
import { getSubmission } from '../../graphql/queries';
// import { SUBMISSION_STATUS_COMPELETED } from '../common';
import './TrackingView.css';

class TrackingView extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount = async () => {
    await this.trackSubmissionByReferenceNumber();
  }

  trackSubmissionByReferenceNumber = async () => {
    // if (!this.state.referenceNumber) throw new Error(I18n.get('submission_not_found'));
    // const { id, info_unixtimestamp } = extractReferenceNumber(this.state.referenceNumber);
    // const { id, info_unixtimestamp } = extractReferenceNumber("A#2020CIAC#jinleidoc@gmail.com#1591838420");
    const { id, info_unixtimestamp } = extractReferenceNumber("A#2020CIAC#charles.gao.92@gmail.com#1578192209");
    const res = await API.graphql(graphqlOperation(getSubmission, { id, info_unixtimestamp }));
    // const res = await API.graphql(graphqlOperation(getSubmission, { id, info_unixtimestamp }));
    console.log(res);
    // if (!res.data.getSubmission) {
    //   throw new Error(I18n.get('submission_not_found'));
    // }
  }

  render() {
    const { } = this.state;

    return (
      <div className="tracking-page">
        Tracking View
      </div>
    );
  }
}

export default TrackingView;