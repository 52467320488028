import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Alert } from 'react-bootstrap';
import { getUrlParams, extractReferenceNumber } from '../common';
import { I18n } from 'aws-amplify';
import API, { graphqlOperation } from '@aws-amplify/api';
import { getSubmission } from '../../graphql/queries';
import { SUBMISSION_STATUS_COMPELETED } from '../common';
import './CompleteView.css';

class CompleteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmissionCompleted: false,
      errorMessage: null,
      referenceNumber: getUrlParams().referenceNumber, // referenceNumber: B#2020CIAC#charles.gao.92@gmail.com#1580592023 user:test@gmail.com
      submission: null,
    }
  }

  componentDidMount = async () => {
    try {
      await this.refreshSubmission();
    } catch (e) {
      this.setState({ errorMessage: e.toString() })
    }
  }

  refreshSubmission = async () => {
    if (!this.state.referenceNumber) throw new Error(I18n.get('submission_not_found'));
    const { id , info_unixtimestamp } = extractReferenceNumber(this.state.referenceNumber);
    const res = await API.graphql(graphqlOperation(getSubmission, { id, info_unixtimestamp }));      
    if (!res.data.getSubmission) {
      throw new Error(I18n.get('submission_not_found'));
    }
    this.setState({ 
      submission: res.data.getSubmission, 
      isSubmissionCompleted: res.data.getSubmission.status === SUBMISSION_STATUS_COMPELETED,
    });
  }

  render() {
    const { isSubmissionCompleted, submission, errorMessage } = this.state;

    return(
      <div className="complete-page">

        {!!errorMessage && <Alert variant="danger">
          <p>{errorMessage}</p>
        </Alert>}

        {isSubmissionCompleted && <Alert variant="success">
        <Alert.Heading>{I18n.get('submit_seccess_message')}</Alert.Heading>
          <p>{I18n.get('submit_seccess_message_reference_number')}</p> 
          <strong>{submission.reference_number}</strong>
          <p>{I18n.get('submit_seccess_message_details')}</p>
        </Alert>}
      </div>
    );
  }
}

export default withAuthenticator(CompleteView);