import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react'
import { I18n } from 'aws-amplify';
import QRCode from 'qrcode.react';
import { FormControl, TextField, Grid, Tabs, Tab } from '@material-ui/core';
import axios from 'axios';
import { generateSnappayH5PayRequest, generateSnappayQrCodePayRequest, isSnappayRequestSuccess, keepCheckingIfSnappayPaymentSucceeded, SNAPPAY_GATEWAY_URL } from './SnappayPaymentHelper';
import { isMonerisPaymentSuccess, monerisPaymentClient2 } from './MonerisPaymentHelper';
import { AXIOS_REQUEST_CONFIG, PAYMENT_TYPE_SNAPPAY_WECHATPAY, PAYMENT_TYPE_CREDIT_CARD_MONERIS_PAYMENT, PAYMENT_TYPE_PAYMENT_WAIVED } from './common';
import { SUBMISSION_STATUS_WAIT_FOR_PAYMENT, PAYMENT_PROCESS_SUCCEEDED, CHARGE_PER_ARTWORK, PAYMENT_STATUS_PAYING, PAYMENT_STATUS_COMPELETED, SUBMISSION_STATUS_COMPELETED, PAYMENT_CURRENCY, getUrlParams, extractReferenceNumber } from '../common';
import API, { graphqlOperation } from '@aws-amplify/api';
import { updateSubmission } from '../../graphql/mutations';
import { getSubmission } from '../../graphql/queries';
import { Alert, Button } from 'react-bootstrap';
import './PaymentEdit.css';

class PaymentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: PAYMENT_TYPE_SNAPPAY_WECHATPAY,
      errorMessage: null,
      referenceNumber: getUrlParams().referenceNumber, // referenceNumber: B#2020CIAC#charles.gao.92@gmail.com#1580592023 user:test@gmail.com
      isPaymentSuccessRedirect: getUrlParams().paymentStatus === PAYMENT_PROCESS_SUCCEEDED,
      submission: null,
      transAmount: null,
      qrCodeUrl: null,
      creditCardNumber: "",
      creditCardExpiryDate: "",
      payButtonDisabled: false,
    }
  }

  componentDidMount = async () => {
    try {
      this.state.isPaymentSuccessRedirect ? await this.verifyPayment() : await this.initPaymentPage();
    } catch (e) {
      this.setState({ errorMessage: e.toString() })
    }
  }

  verifyPayment = async () => {
    await this.refreshSubmission();
    if (this.state.submission.payment_status === PAYMENT_STATUS_COMPELETED) {
      window.location.href = '/complete?referenceNumber=' + this.state.submission.reference_number;
    }
  }
  
  refreshSubmission = async () => {
    if (!this.state.referenceNumber) throw new Error(I18n.get('submission_not_found'));
    const { id , info_unixtimestamp } = extractReferenceNumber(this.state.referenceNumber);
    const res = await API.graphql(graphqlOperation(getSubmission, { id, info_unixtimestamp }));      
    if (!res.data.getSubmission) {
      throw new Error(I18n.get('submission_not_found'));
    }
    this.setState({ 
      submission: res.data.getSubmission, 
      transAmount: res.data.getSubmission.num_of_artworks * CHARGE_PER_ARTWORK,
    });
  }

  initPaymentPage = async () => {
    await this.checkIfNeedToPay();
  }

  goToNextPage = () => {
    window.location.href = '/complete?referenceNumber=' + this.state.referenceNumber;
  }

  checkIfNeedToPay = async () => {
    await this.verifyPayment();
    await this.checkIfPaymentWaived();
    if (this.state.submission.status !== SUBMISSION_STATUS_WAIT_FOR_PAYMENT) {
      throw new Error(I18n.get('payment_status_invalid'));
    }
  }

  /**
   * Check if payment waived. 
   * If yes, then ignore payment and redirect to next page. 
   * Otherwise, proceed to payment.
   */
  checkIfPaymentWaived = async () => {
    let paymentWaived = false;
    if (new Date() < new Date("2022-12-01")) {
      // For 2022, submissions before Dec (in local time) is free
      paymentWaived = true;
    }

    if (paymentWaived) {
        await API.graphql(graphqlOperation(updateSubmission, { input: {
          id: this.state.submission.id,
          info_unixtimestamp: this.state.submission.info_unixtimestamp,
          payment_method: PAYMENT_TYPE_PAYMENT_WAIVED,
          payment_status: PAYMENT_STATUS_COMPELETED,
          status: SUBMISSION_STATUS_COMPELETED,
        }
      }));
      this.goToNextPage();
    }
  }

  onCreditCardNumberChange = (event) => { this.setState({ creditCardNumber: event.target.value })}
  onCreditCardExpiryDateChange = (event) => { this.setState({ creditCardExpiryDate: event.target.value })}

  onPaymentMethodChangedToWeChatPay = () => { this.setState({ paymentMethod: PAYMENT_TYPE_SNAPPAY_WECHATPAY }); }
  onPaymentMethodChangedToCreditCardMonerisPayment = () => { this.setState({ paymentMethod: PAYMENT_TYPE_CREDIT_CARD_MONERIS_PAYMENT }); }

  onWeChatPay = async () => {
    try {
      await this.checkIfNeedToPay();
      const userOnMobile = false; // TODO: isUserOnMobile();
      const req = {
        referenceNumber: this.state.referenceNumber,
        transAmount: this.state.transAmount,
      }
      const request = userOnMobile ? generateSnappayH5PayRequest(req) : generateSnappayQrCodePayRequest(req);
      const res = await axios.post(SNAPPAY_GATEWAY_URL, request, AXIOS_REQUEST_CONFIG);
      if (!isSnappayRequestSuccess(res)) throw new Error(res.data.msg);

      const out_order_no = res.data.data[0].out_order_no;
      const trans_no = res.data.data[0].trans_no !== null ? res.data.data[0].trans_no : null;
      await API.graphql(graphqlOperation(updateSubmission, { input: {
          id: this.state.submission.id,
          info_unixtimestamp: this.state.submission.info_unixtimestamp,
          payment_method: PAYMENT_TYPE_SNAPPAY_WECHATPAY,
          payment_amount: this.state.transAmount,
          payment_currency: PAYMENT_CURRENCY,
          payment_status: PAYMENT_STATUS_PAYING,
          payment_trans_no_snappay: trans_no,
          payment_out_order_no_snappay: out_order_no,
        }
      }));

      if (userOnMobile) { // on mobile -> redirect to WeChat native app
        window.location.href = res.data.data[0].h5pay_url;
      } else { // on website -> scan QR code using WeChat app
        this.setState({ qrCodeUrl: res.data.data[0].qrcode_url })
      }
      const succeeded = await keepCheckingIfSnappayPaymentSucceeded({ outOrderNo: out_order_no });
      if (succeeded) {
        await this.onWeChatPayPaymentCompleted({ trans_no, out_order_no });
        this.goToNextPage();
      } else {
        window.location.reload(); // if after a period of time the payment still not complete, then force user to start over
      }
    } catch (e) {
      this.setState({ errorMessage: e.toString() })
    }
  }

  onWeChatPayPaymentCompleted = async ({ trans_no, out_order_no }) => {
    await API.graphql(graphqlOperation(updateSubmission, { input: {
        id: this.state.submission.id,
        info_unixtimestamp: this.state.submission.info_unixtimestamp,
        payment_method: PAYMENT_TYPE_SNAPPAY_WECHATPAY,
        payment_status: PAYMENT_STATUS_COMPELETED,
        payment_timestamp: new Date().toISOString(),
        payment_trans_no_snappay: trans_no,
        payment_out_order_no_snappay: out_order_no,
        status: SUBMISSION_STATUS_COMPELETED,
      }
    }));
  }

  onMonerisPay = async (event) => {
    event.preventDefault(); // prevent the page refresh
    this.setState({ payButtonDisabled: true });
    try {
      this.checkIfNeedToPay();
      const purchase = {
        amount: this.state.transAmount,
        card: this.state.creditCardNumber,
        expiry: this.state.creditCardExpiryDate.substring(0, 4),
        description: this.state.referenceNumber,
      }

      monerisPaymentClient2.pay(purchase, async (err, result) => {
        if (isMonerisPaymentSuccess(result)) {
          await API.graphql(graphqlOperation(updateSubmission, {
            input: {
              id: this.state.submission.id,
              info_unixtimestamp: this.state.submission.info_unixtimestamp,
              payment_method: PAYMENT_TYPE_CREDIT_CARD_MONERIS_PAYMENT,
              payment_amount: this.state.transAmount,
              payment_currency: PAYMENT_CURRENCY,
              payment_status: PAYMENT_STATUS_COMPELETED,
              payment_timestamp: new Date().toISOString(),
              payment_order_id_moneris: result.ReceiptId[0],
              payment_reference_moneris: result.ReferenceNum[0],
              status: SUBMISSION_STATUS_COMPELETED,
            }
          }));
          this.goToNextPage();
        } else {
          console.error("Moneris Payment Failed:");
          console.error(result);
          this.setState({
            errorMessage: result.Message[0],
            payButtonDisabled: false,
          });
        }
      });
    } catch (e) {
      this.setState({
        errorMessage: e.msg || e.toString(),
        payButtonDisabled: false,
      });
    }
  }

  render() {
    const { errorMessage, paymentMethod, submission, transAmount, qrCodeUrl, creditCardNumber, creditCardExpiryDate, payButtonDisabled} = this.state;

    return (
      <div className='payment-page'>
        {!!errorMessage && <Alert variant="danger">
          <p>{errorMessage}</p>
        </Alert>}

        {submission && <div>
          <Grid className='grid-input'>
            <Tabs
              className='tabs'
              value={paymentMethod}
              indicatorColor="primary"
              textColor="primary">
              <Tab label={I18n.get('payment_snappay_wechat_pay')} value="SNAPPAY_WECHATPAY" onClick={this.onPaymentMethodChangedToWeChatPay} />
              <Tab label={I18n.get('payment_credit_card_moneris_payment')} value="CREDIT_CARD_MONERIS_PAYMENT" onClick={this.onPaymentMethodChangedToCreditCardMonerisPayment} />
            </Tabs>
          </Grid>

          <div className="payment-note">
            <span>{I18n.get('payment_helper_text1')} </span>
            <span className="bold">{submission.num_of_artworks} </span>
            <span>{I18n.get('payment_helper_text2')} </span>
            <span className="bold">{transAmount} </span>
            <span>{I18n.get('payment_helper_text3')} </span>
          </div>

          {paymentMethod === PAYMENT_TYPE_SNAPPAY_WECHATPAY && qrCodeUrl 
            && <QRCode className="qr-code" value={qrCodeUrl} />}
          
          {paymentMethod === PAYMENT_TYPE_SNAPPAY_WECHATPAY 
          && !qrCodeUrl && 
          <div className="payment-note-snappay">
            <div className="payment-note">{I18n.get('payment_helper_text_snappay_wechat_pay')}</div>
            <div className="payment-button" >
              <Button onClick={this.onWeChatPay}>
                {I18n.get('payment_button')}
              </Button>
            </div>
          </div>}

          {paymentMethod === PAYMENT_TYPE_CREDIT_CARD_MONERIS_PAYMENT &&
          <div className="credit-card-payment-form">
            <form onSubmit={this.onMonerisPay}>
              <Grid className='grid-input'>
                <FormControl className='form-input'>
                  <TextField
                    id="form-credit-card-number"
                    inputProps={{ pattern: "[0-9]{13,19}" }}
                    placeholder="xxxx xxxx xxxx xxxx"
                    label={I18n.get('credit_card_number')}
                    variant="outlined"
                    size="small"
                    value={creditCardNumber}
                    onChange={this.onCreditCardNumberChange}
                    required />
                </FormControl>
              </Grid>
              <Grid className='grid-input'>
                <FormControl className='form-input'>
                  <TextField
                    id="form-credit-card-expiry-date"
                    inputProps={{ pattern: "[0-9]{4}" }}
                    placeholder="MMYY"
                    label={I18n.get('credit_card_expiry_date')}
                    variant="outlined"
                    size="small"
                    value={creditCardExpiryDate}
                    onChange={this.onCreditCardExpiryDateChange}
                    required />
                </FormControl>
              </Grid>
              <Button className="payment-button" type="submit" disabled={payButtonDisabled}>
                {I18n.get('payment_button')}
              </Button>
            </form>
          </div>}
        </div>}
      </div>
    )
  }
}

export default withAuthenticator(PaymentEdit);