/**
 * NPM: 
 *   - monerisPaymentClient: https://www.npmjs.com/package/moneris-node
 *   - monerisPaymentClient2: Inspired by https://github.com/manixate/mpgapi-node
 * Moneris Testing: https://developer.moneris.com/en/More/Testing/Testing%20a%20Solution
 */

import moneris from 'moneris-node';
import { post } from './MonerisClient';

const MONERIS_STORE_ID = 'gwca027774';
const MONERIS_API_TOKEN = 'SJaOatcdEkQGBLDE3XNR';

// For testing
// export const MONERIS_STORE_ID = 'store5';
// export const MONERIS_API_TOKEN = 'yesguy';

/**
 * We cannot use monerisPaymentClient for purchase since currently 
 * Moneris do not allow all origins to fetch their resources which we will get CORS error.
 * Before this, we will be using monerisPaymentClient2 + proxy server.
 */
export const monerisPaymentClient = moneris({
	app_name: 'ARTISTS\' ASSOCIATION OF CANADA',
  store_id: MONERIS_STORE_ID,
  api_token: MONERIS_API_TOKEN,
  test: true,
})

// For Moneris Proxy Servers
// export const MONERIS_PROXY_SERVER_URL = 'https://node-express-env.eba-mttrqz4e.ap-northeast-1.elasticbeanstalk.com/gateway2/servlet/MpgRequest';
export const MONERIS_PROXY_SERVER_URL = 'https://proxy.canadaartcompetition.com/gateway2/servlet/MpgRequest';
export const MONERIS_PROXY_SERVER_URL_LOCAL = 'http://localhost:3001/gateway2/servlet/MpgRequest';

export const monerisPaymentClient2 = {
	pay: (purchase, callback) => {
		const timestamp = new Date().toISOString();
		const purchasePayload = {
			cust_id: "customerId-" + purchase.description + "-" + timestamp,
			order_id: "orderId-" + purchase.description + "-" + timestamp,
			amount: Number(purchase.amount).toFixed(2),
			pan: purchase.card,
			expdate: purchase.expiry,
			crypt_type: 7,
			dynamic_descriptor: purchase.description,
			status_check: false,
		};

		post(MONERIS_STORE_ID, MONERIS_API_TOKEN, 'purchase', purchasePayload, callback)
	}
}

/**
 * Check if Moneris payment succeeded
 * https://developer.moneris.com/en/More/Testing/Response%20Codes
 * 
 * @param {Object} response get from Moneris
 */
export const isMonerisPaymentSuccess = (response) => {
	return response.ResponseCode[0] !== null
		&& response.ResponseCode[0] !== "null"
		&& response.ResponseCode[0] < 50;
}
