/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSubmission = /* GraphQL */ `
  query GetSubmission($id: ID!, $info_unixtimestamp: String!) {
    getSubmission(id: $id, info_unixtimestamp: $info_unixtimestamp) {
      id
      info_unixtimestamp
      owner
      timestamp
      status
      contest_name
      reference_number
      participant
      date_of_birth
      email
      country
      state
      city
      school
      instructor
      artwork_name
      artwork_material
      artwork_summary
      artwork_file_size
      artwork_file_location
      num_of_artworks
      payment_method
      payment_amount
      payment_timestamp
      payment_currency
      payment_status
      payment_trans_no_snappay
      payment_out_order_no_snappay
      payment_order_id_moneris
      payment_reference_moneris
      createdAt
      updatedAt
    }
  }
`;
export const listSubmissions = /* GraphQL */ `
  query ListSubmissions(
    $id: ID
    $info_unixtimestamp: ModelStringKeyConditionInput
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubmissions(
      id: $id
      info_unixtimestamp: $info_unixtimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        info_unixtimestamp
        owner
        timestamp
        status
        contest_name
        reference_number
        participant
        date_of_birth
        email
        country
        state
        city
        school
        instructor
        artwork_name
        artwork_material
        artwork_summary
        artwork_file_size
        artwork_file_location
        num_of_artworks
        payment_method
        payment_amount
        payment_timestamp
        payment_currency
        payment_status
        payment_trans_no_snappay
        payment_out_order_no_snappay
        payment_order_id_moneris
        payment_reference_moneris
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
