export const dic = {
    'en': {
        'acknowledgement_error_message': "Please make sure you acknowledge the terms and conditions.",
        'acknowledgement_individual': "As student guardian, I have read and agreed to the terms of conditions of 2023 Canada International Art Competition.",
        'acknowledgement_organization': "On behalf of our organization, we have read and agreed to the terms of conditions of 2023 Canada International Art Competition.  we confirmed that we have communicated with all participants' guardians who also agree to it.",
        'artwork_material': "Artwork Material",
        'artwork_material_helper_text': "The materials used in the artwork",
        'artwork_name': "Artwork Name",
        'artwork_name_helper_text': "The name of the artwork",
        'artwork_summary': "Artwork Summary",
        'artwork_summary_helper_text': "A summary of the artwork",
        'artworks_number_limit_error_message': "Please make sure you uploaded 1 file.",
        'artworks_drop_file_area_helper_text_individual': "You are only allowed to upload one file with JPG, JPEG, GIF or PNG format. The file size limit is 5MB.",
        'artworks_drop_file_area_helper_text_organization': "You are only allowed to upload one file with zip format.",
        'city': "City",
        'credit_card_number': "Credit Card Number (xxxx xxxx xxxx xxxx)",
        'credit_card_expiry_date': "Expiry Date (MMYY)",
        'coming_soon': "Coming Soon!",
        'country': "Country of Residency/Nation",
        'date_of_birth': "Date of Birth of Participant",
        'email_address': "Email Address",
        'email_address_helper_text': "Your account email will be used and we will be using email as the main contact method.",
        'individual': "Individual",
        'instructor': "Name of the Art Teacher",
        'instructor_helper_text': "Legal name of the instructor in the format of (Last name, first name). e.g.: Smith, John",
        'more_info_part1': "Please visit ",
        'more_info_part2': " for details of this competition.",
        'num_of_artworks': "Number of Artworks",
        'organization': "Organization",
        'participant': "Name of the Participant",
        'participant_helper_text': "Legal name of the participant in the format of (Last name, first name). e.g.: Smith, John",
        'payment_button': "Pay",
        'payment_helper_text1': "You will be charged 20 USD for each artwork. Since you submitted ",
        'payment_helper_text2': "artwork(s), you will be charged for ",
        'payment_helper_text3': "USD. If you are willing to pay, please click this button to proceed. You will NOT be refund once the payment completed.",
        'payment_helper_text_snappay_wechat_pay': "You will need to use your WeChat app to scan the QR code. Please contact us if you need further assistance.",
        'payment_snappay_wechat_pay': "WeChat Pay",
        'payment_status_invalid': "You are not ready to be proceed for the payment.",
        'payment_credit_card_moneris_payment': "Credit Card", // Moneris is the only payment method for Credit card
        'school': "School",
        'state': "Province/State",
        'submission_not_found': "We are not able to find your submission, please check your reference number or contact us.",
        'submit': "Submit",
        'submit_modal_header': "Submission",
        'submit_modal_body': "You are about to submit your application. You will not be able to modify your application after you submitted.",
        'submit_modal_confirm': "Confirm",
        'submit_modal_close': "Close",
        'submit_seccess_message': "You have submitted succesfully!",
        'submit_seccess_message_reference_number': "Your reference number is: ",
        'submit_seccess_message_details': "A confirmation email with this reference number has been sent to you.",
        'submit_error_message': "Submission failed due to: ",
        'username': "Username",
    },
    'cn': {
        'artworks_number': "Number of Artworks",
        'artworks_number_limit_error_message': "Please make sure you uploaded 1 zip file",
        'artworks_drop_file_area_helper_text': "You are only allowed to upload one zip file. The file size limit for individual candidate is 5MB.",
        'cell': "Cell",
        'city': "City",
        'country': "Country of Residency/Nation",
        'date_of_birth': "Date of Birth of Participant",
        'email_address': "电子邮箱",
        'email_address_helper_text': "Your account email will be used and we will be using email as the main contact method.",
        'guardian_contact': "Parents/Guardian/School Contact Number",
        'individual': "Individual",
        'instructor': "Name of the Instructor",
        'instructor_helper_text': "Legal name of the instructor in the format of (Last name, first name). e.g.: Smith, John",
        'organization': "Organization",
        'participant': "Name of the Participant",
        'participant_helper_text': "Legal name of the participant in the format of (Last name, first name). e.g.: Smith, John",
        'school': "School",
        'state': "Province/State",
        'submit': "提交",
        'submit_modal_header': "Submission",
        'submit_modal_body': "You are going to submit your application. You will not be able to modify your application after you confimed.",
        'submit_modal_confirm': "Confirm",
        'submit_modal_close': "Close",
        'submit_seccess_message': "您已成功提交！",
        'submit_seccess_message_reference_number': "Your reference number is: ",
        'submit_seccess_message_details': "You may want to close this window now.",
        'submit_error_message': "递交失败：",
        'username': "Username",
        'zip_code': "Zip Code",
    },
}