import MD5 from 'md5';
import axios from 'axios';
import { AXIOS_REQUEST_CONFIG, getPaymentSuccessRedirectUrl } from './common';

/**
 * http://developer.snappay.ca/openapi.html#header-2.-access-nodes
 */
export const SNAPPAY_GATEWAY_URL = 'https://open.snappay.ca/api/gateway';
export const PAYMENT_SUCCESSFUL_CHECK_TIMES = 1200;

/**
 * DO NOT EXPOSE ABOVE DATA TO PUBLIC!
 */
const SNAPPAY_MERCHANT_NO = '902000001151';
const SNAPPAY_SIGN_KEY = '30ab16cb2c4708a34d2d386c29b70343';
const SNAPPAY_APP_ID = 'a955a71d454e24f6';

/**
 * http://developer.snappay.ca/openapi.html#pay-apis-h5-pay-api-post
 * @param {*} original 
 * e.g.: 
 * {
  "app_id": "6bf9403d0c97bd24",
  "charset": "UTF-8",
  "description": "this is a test transaction",
  "effective_minutes": 15
  "format": "JSON",
  "merchant_no": "901800002555",
  "method": "pay.h5pay",
  "notify_url": "https://notify-url",
  "out_order_no": "12345678",
  "payment_method": "WECHATPAY",
  "pay_channel_trade_type": "MWEB",
  "return_url": "https://return-url",
  "sign_type": "MD5",
  "sign": "7e2083699dd510575faa1c72f9e35d43",
  "timestamp": "2018-08-02 15:16:51",
  "trans_amount": 1.5,
  "trans_currency": "CAD",
  "version": "1.0",
}
 */
export const generateSnappayH5PayRequest = (req) => {
  const outOrderNo = generateOutOrderNo();

  const original = {
    "app_id": SNAPPAY_APP_ID,
    "charset": "UTF-8",
    "description": req.referenceNumber,
    "effective_minutes": "15",
    "format": "JSON",
    "merchant_no": SNAPPAY_MERCHANT_NO,
    "method": "pay.h5pay",
    "out_order_no": outOrderNo,
    "payment_method": "WECHATPAY",
    "return_url": getPaymentSuccessRedirectUrl(req.referenceNumber),
    "trans_amount": req.transAmount,
    "trans_currency": "USD",
    "version": "1.0",
    "sign_type": "MD5",
  }

  let parameters = [
    "app_id=" + SNAPPAY_APP_ID,
    "charset=UTF-8",
    "description=" + req.referenceNumber,
    "effective_minutes=15",
    "format=JSON",
    "merchant_no=" + SNAPPAY_MERCHANT_NO,
    "method=pay.h5pay",
    "out_order_no=" + outOrderNo,
    "payment_method=WECHATPAY",
    "return_url=" + getPaymentSuccessRedirectUrl(req.referenceNumber),
    "trans_amount=" + req.transAmount,
    "trans_currency=USD",
    "version=1.0",
  ];

  const request = Object.assign({}, original);
  request.sign = MD5(parameters.join('&').concat(SNAPPAY_SIGN_KEY)).toLowerCase();

  return request;
}

/**
 * http://developer.snappay.ca/openapi.html#pay-apis-transaction-qr-code-pay-api-post
 * @param {*} original 
 * e.g.: 
 * {
  "app_id": "9f00cd9a873c511e",
  "charset": "UTF-8",
  "description": "this is a test transaction",
  "effective_minutes": "15",
  "format": "JSON",
  "merchant_no": "901800000116",
  "method": "pay.qrcodepay",
  "out_order_no": "12345678",
  "payment_method": "WECHATPAY",
  "return_url": "https://www.canadaartcompetition.com/payment?status=SUCCESS",
  "trans_amount": 1.5,
  "trans_currency": "CAD",
  "version": "1.0",
  "sign_type": "MD5",
}
 */
export const generateSnappayQrCodePayRequest = (req) => {
  const outOrderNo = generateOutOrderNo();

  const original = {
    "app_id": SNAPPAY_APP_ID,
    "charset": "UTF-8",
    "description": req.referenceNumber,
    "effective_minutes": "15",
    "format": "JSON",
    "merchant_no": SNAPPAY_MERCHANT_NO,
    "method": "pay.qrcodepay",
    "out_order_no": outOrderNo,
    "payment_method": "WECHATPAY",
    "return_url": getPaymentSuccessRedirectUrl(req.referenceNumber),
    "trans_amount": req.transAmount,
    "trans_currency": "USD",
    "version": "1.0",
    "sign_type": "MD5",
  }

  let parameters = [
    "app_id=" + SNAPPAY_APP_ID,
    "charset=UTF-8",
    "description=" + req.referenceNumber,
    "effective_minutes=15",
    "format=JSON",
    "merchant_no=" + SNAPPAY_MERCHANT_NO,
    "method=pay.qrcodepay",
    "out_order_no=" + outOrderNo,
    "payment_method=WECHATPAY",
    "return_url=" + getPaymentSuccessRedirectUrl(req.referenceNumber),
    "trans_amount=" + req.transAmount,
    "trans_currency=USD",
    "version=1.0",
  ];

  const request = Object.assign({}, original);
  request.sign = MD5(parameters.join('&').concat(SNAPPAY_SIGN_KEY)).toLowerCase();

  return request;
}

export const keepCheckingIfSnappayPaymentSucceeded = async (req) => {
  const original = {
    "app_id": SNAPPAY_APP_ID,
    "charset": "UTF-8",
    "format": "JSON",
    "merchant_no": SNAPPAY_MERCHANT_NO,
    "method": "pay.orderquery",
    "out_order_no": req.outOrderNo,
    "version": "1.0",
    "sign_type": "MD5",
  }

  let parameters = [
    "app_id=" + SNAPPAY_APP_ID,
    "charset=UTF-8",
    "format=JSON",
    "merchant_no=" + SNAPPAY_MERCHANT_NO,
    "method=pay.orderquery",
    "out_order_no=" + req.outOrderNo,
    "version=1.0",
  ];

  const request = Object.assign({}, original);
  request.sign = MD5(parameters.join('&').concat(SNAPPAY_SIGN_KEY)).toLowerCase();

  for (let t = PAYMENT_SUCCESSFUL_CHECK_TIMES; t > 0; t--) {
    const res = await axios.post(SNAPPAY_GATEWAY_URL, request, AXIOS_REQUEST_CONFIG);
    await sleep(500);
    const succeeded = res
      && res.status === 200
      && res.data.code === '0'
      && res.data.data[0].trans_status === 'SUCCESS';
    if (succeeded) {
      return true;
    }
  }
  return false;
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Check if Snappay request success
 * @param {*} response 
 */
export const isSnappayRequestSuccess = (response) => {
  return response.status === 200
    && response.data.code === '0'
    && response.data.data[0].trans_status === 'USERPAYING'
    && !!response.data.sign;
}

export const generateOutOrderNo = () => {
  return Math.round(Math.random() * 100000000);
}